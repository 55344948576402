import React from 'react'
import _ from 'lodash'

import TextGroup from '../Texts'

const StrategyOne = ({ lang }) => {
const isEn = lang === 'en';
  return (
    <section id="strategy-one" className="content container">
        {isEn ? (
          <a href="/en/strategy-ESG/" className="back-strategy">
            Back to ESG Strategy
          </a>
        ) : (
          <a href="/estrategia-ESG/" className="back-strategy">
            Voltar para Estratégia ESG
          </a>
        )}
      <TextGroup
        rootProps={{ className: 'align-center' }}
        title={{
          type: 'h1',
          text: isEn ? 'Anti-Corruption Policy' : 'Política De Anticorrupção',
        }}
      />
      {isEn ? (
        <>
        <p>log.oscon undertakes to ensure compliance with ethical and professional rules by
        itself, its employees, workers and staff, namely all written or verbal policies to which
        it has access, including the relevant log.oscon policies, or any other documents
        that may be available on the matter.</p>
        <p>In carrying out its activities, log.oscon undertakes to act in strict compliance with
        the legislation on preventing and combating corruption, influence peddling and the
        laundering of ill-gotten gains, namely not to offer, directly or indirectly, advantages
        to third parties, nor to solicit, promote or accept, for its own benefit or that of
        others, advantages for the purpose of obtaining a favourable outcome.</p>

        <a href="/en/strategy-ESG/" class="back-strategy-last">Back to ESG Strategy</a>
        </>
      ) : (
        <>
        <p>A log.oscon compromete-se assegurar por si, pelos seus colaboradores,
        trabalhadores e pessoal, o cumprimento das regras deontológicas e profissionais,
        designadamente todas as políticas escritas ou verbais a que tenha acesso,
        incluindo as políticas relevantes da log.oscon, ou quaisquer outros documentos
        que venham a dispor sobre a matéria.</p>
        <p>A log.oscon compromete-se, no desenvolvimento das suas atividades a atuar em
        estrito cumprimento da legislação sobre prevenção e combate à corrupção, ao
        tráfico de influência e ao branqueamento de vantagens de proveniência ilícita,
        nomeadamente, a não oferecer, direta ou indiretamente, vantagens a terceiros,
        nem solicitar, promover ou aceitar, para benefício próprio ou de outrem, vantagens
        com o propósito de obter um desfecho favorável.</p>

        <a href="/estrategia-ESG/" class="back-strategy-last">Voltar para Estratégia ESG</a>
        </>
      )}
    </section>
  )
}
export default StrategyOne
