import React from 'react'

import Layout from '../components/layout'
import CorruptionOne from '../components/strategy/CorruptionOne'


const Work = props => (
  <Layout lang="en" title="Anti-Corruption Policy" meta={{description: ''}} rootProps={{ className: 'page strategy' }}>
    <CorruptionOne lang="en" />
  </Layout>
)

export default Work
